import React from 'react';

import css from './style.module.scss';
import UsMap from '../us-map';

export default props => {
  return props.licensedInOpened ? (
    <div className={`${css.licensedInContainer}`}>
      <div
        onClick={() => {
          props.setLicensedInOpened && props.setLicensedInOpened(false);
        }}
        className={css.close}>
        X
      </div>
      <div className={css.text}>
        <div className={css.markets}>MARKETS WE SERVE</div>
        <div className={css.clickToLearn}>Click on the locator to learn more</div>
        <div className={css.description}>
          Having insurance is necessary, but it can also be expensive, and Apollo Insurance Group, Inc understands that not everyone can afford it. Our mission
          at Apollo is to provide people with a wide selection of affordable insurance that includes life insurance, health insurance, individual insurance,
          group insurance and much more. We are licensed in many states and provide services in Kansas, Missouri, Nebraska, and Iowa.
        </div>
      </div>
      <div className={css.map}>
        <UsMap />
      </div>
    </div>
  ) : null;
};
