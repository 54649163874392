import React, { useState } from 'react';

import css from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default props => {
  const [opened, setOpened] = useState(false);

  return (
    <div
      className={`${css.amenu} ${opened ? css.opened : ''}`}
      onClick={() => {
        setOpened(!opened);
      }}>
      <svg className={css.svg} viewBox='0 0 100 100'>
        <path
          className={`${css.line} ${css.line1}`}
          d='M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
        />
        <path className={`${css.line} ${css.line2}`} d='M 20,50 H 80' />
        <path
          className={`${css.line} ${css.line3}`}
          d='M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
        />
      </svg>
      {opened && (
        <div className={css.mobileMenu}>
          <nav className='menu'>
            <a href='/#home'>HOME</a>
            <a href='/about#'>ABOUT</a>
            <a className='dropdown' href='/insurance'>
              INSURANCE
            </a>
            <a href='/medicare'>MEDICARE</a>
            <a href='/careers'>CAREERS</a>
            <a href='/resources'>RESOURCES</a>

            <div
              onClick={() => {
                props.setLicensedInOpened && props.setLicensedInOpened(!props.licensedInOpened);
              }}
              className='licensed-in cursor-on-hover'>
              <div>
                <span className='licensed-in-text'>LICENSED IN</span>
                <FontAwesomeIcon icon={faCaretDown} className='carrot' />
              </div>
              <img className='us-map' src='/usa.png' />
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};
