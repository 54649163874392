import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';

import CloseButton from '../animated-close-button';
import LicencedIn from '../licensed-in';

export default props => (
  <header className='header-container'>
    <div
      onClick={() => {
        props.setGetAQuoteOpened && props.setGetAQuoteOpened(true);
      }}
      className='pre-header'>
      <div className='pre-header-phone'>
        <svg>
          <use xlinkHref='/sprite.svg#phone-outlined-icon' />
        </svg>
        913-279-0077
      </div>
      <div
        onClick={() => {
          props.setGetAQuoteOpened && props.setGetAQuoteOpened(true);
        }}
        className='pre-header-quote'>
        GET A QUOTE
      </div>
    </div>
    <div className='header'>
      <div className='header-left'>
        <a href='/'>
          <svg>
            <use xlinkHref='#apollo-new-logo-icon' />
          </svg>
        </a>
      </div>

      <div className='header-right'>
        <div className='header-right-left'>
          <nav className='menu'>
            <a className={props.activePage == 'home' ? 'active-link' : ''} href='/#home'>
              HOME
            </a>
            <a className={props.activePage == 'about' ? 'active-link' : ''} href='/about#'>
              ABOUT
            </a>
            <a className={`${props.activePage == 'insurance' ? 'active-link' : ''} dropdown`} href='/insurance'>
              <span>INSURANCE</span>
              <div className='dropdown-content'>
                <div>
                  <a className='sublink' href='/insurance/individual'>
                    INDIVIDUAL{' '}
                  </a>
                </div>
                <br />
                <div>
                  <a className='sublink' href='/insurance/group'>
                    GROUP
                  </a>
                </div>
                <br />

                <div>
                  <a className='sublink' href='/insurance/life'>
                    LIFE
                  </a>
                </div>
                <br />

                <div>
                  <a href='/insurance/vision' className='sublink'>
                    VISION
                  </a>
                </div>
                <br />

                <div>
                  <a href='/insurance/dental' className='sublink'>
                    DENTAL
                  </a>
                </div>
              </div>
            </a>
            <a className={`${props.activePage == 'medicare' ? 'active-link' : ''}`} href='/medicare'>
              MEDICARE
            </a>

            <a href='/resources'>RESOURCES</a>

            <a className={props.activePage == 'careers' ? 'active-link' : ''} href='/careers'>
              CAREERS
            </a>
          </nav>

          <FontAwesomeIcon icon={faSearch} className='search' />
        </div>

        <div className='header-right-right'>
          <div
            onClick={() => {
              props.setLicensedInOpened && props.setLicensedInOpened(!props.licensedInOpened);
            }}
            className='licensed-in cursor-on-hover'>
            <div>
              <span className={`licensed-in-text ${props.activePage == 'licensed-in' ? 'licensed-in-active-link' : ''}`}> LICENSED IN</span>
              <FontAwesomeIcon icon={faCaretDown} className='carrot' />
            </div>
            <img alt='united states map' className='us-map' src='/usa.png' />
          </div>
        </div>
      </div>
      <CloseButton {...props} />
      <LicencedIn active={props.activePage == 'licensed-in'} {...props} />
    </div>
  </header>
);
